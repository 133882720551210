.fc {
    .override::before {
        content: "";
        position: absolute;
        width: calc(100% + 6px);
        height: 400%;
        background: rgba(255, 255, 255, 0.5);
        padding: 2px;
        top: 100%;
        margin-top: 2px;
        left: -3px;
        z-index: 1;
        pointer-events: none;
    }
    & .fc-daygrid-day-number {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }


    &-daygrid-day {
        &-top {
            display: block !important;
            position: relative;
            z-index: 4;
        }
        &-frame {
            cursor: pointer;
            &:hover {
                background-color: var(--ui-color-surface-highlight);
            }
        }
        &-number {
            z-index: 10;
            margin: 0 auto;
            color: inherit;
            position: static !important;
            line-height: 0.6;
        }
    }


    &-day {
        line-height: 0.6;

        .fc-day-override {
            font-weight: 600;
            font-size: 10px;
            height: 14px;
            display: flex !important;
        }


        &-today {
            .fc-day-override {
                background-color: var(--default-color-blue);
                color: var(--default-color-white);
                font-weight: 600;
                font-size: 10px;
                border-radius: 20px;
                height: 14px;
                display: flex !important;
            }
        }


        &-other {
            background-color: var(--ui-color-surface-second);
        }
    }

    table.fc-scrollgrid {
        border-top-style: none;
        border-left-style: none;
        & > thead td,
        & > thead th {
            border-right-style: none;
            border-left-style: none;
        }
        & > tbody table {
            border-left: 1px solid #ddd;
        }
    }
    .fc-col-header-cell-cushion {
        color: inherit;
        opacity: 0.3;
        text-transform: uppercase;
        font-weight: normal;
    }
    .fc-daygrid-event {
        height: 1.8rem;
        max-width: 100%;
        z-index: 1;
        &.inactive {
            opacity: 0.5;
            z-index: 10;
        }
        &.override {
            z-index: 10;
        }
        .fc-event-main {
            line-height: 1.6rem;
            margin: 0 4px;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 1.1rem;
            .calendar-event-name {
                display: flex;
                align-items: center;
                max-width: 100%;
            }
            .calendar-event-title {
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-right: 0.5rem;
            }
            .calendar-event-weight {
                margin-left: 3px;
            }

            svg {
                display: block;
            }
        }
    }
}
.fc-direction-ltr .fc-daygrid-week-number {
    left: 1px !important;
    background-color: transparent !important;
    opacity: 0.3 !important;
    top: 2px !important;
}
