.popover-override-backdrop.cdk-overlay-backdrop {
    background-color: var(--ui-color-black);
    opacity: 0.3;
    transition: all 400ms;
    width: 100vw;
    height: 100vh;
    & + .cdk-overlay-connected-position-bounding-box {
        height: 100vh !important;
        width: 100vw !important;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0 !important;
        right: 0 !important;
        top: 0 !important;
        bottom: 0 !important;
        .cdk-overlay-pane {
            display: inline-block;
            position: static;
        }
    }
}
