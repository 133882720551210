.heavy-video-tooltip {
    padding: 0.6rem !important;
    line-height: 1.4rem;
    font-size: 1.2rem;
    text-align: center;
}

.auto-optimize-card-tooltip {
    padding: 0.6rem !important;
    line-height: 1.4rem;
    font-size: 1.1rem;
    text-align: center;
    max-width: 28rem;
}
