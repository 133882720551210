ui-confirm-dialog,
ui-alert-dialog {
    .text {
        padding: 0 !important;
    }
    .warning {
        background-color: var(--ui-color-surface-second);
        border: var(--ui-border);
        padding: var(--default-padding-small);
        display: flex;
        text-align: left;
        align-items: center;
    }
    .warning-icon {
        margin-right: 1.5rem;
        margin-top: 0.3rem;
        min-width: 3rem;
        height: 3rem;
        background-size: cover;
        display: block;
        float: left;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjM0IiBoZWlnaHQ9IjM0IiB2aWV3Qm94PSIwIDAgMTA4OCAxMDg4Ij4NCiAgICAgICAgICAgICAgICAgICAgPHBhdGggZmlsbD0iIzM2MzYzNiIgZD0iTTU0NCAxMDg4Yy0zMDAuNDQzIDAtNTQ0LTI0My41NTctNTQ0LTU0NHMyNDMuNTU3LTU0NCA1NDQtNTQ0YzMwMC40NDMgMCA1NDQgMjQzLjU1NyA1NDQgNTQ0cy0yNDMuNTU3IDU0NC01NDQgNTQ0ek01NDQgMTAzN2MyNzIuMjc2IDAgNDkzLTIyMC43MjQgNDkzLTQ5M3MtMjIwLjcyNC00OTMtNDkzLTQ5M2MtMjcyLjI3NiAwLTQ5MyAyMjAuNzI0LTQ5MyA0OTNzMjIwLjcyNCA0OTMgNDkzIDQ5M3pNNTQ0IDY0NmMtMzcuNTU1IDAtNjgtMzAuNDQ1LTY4LTY4di0yNzJjMC0zNy41NTUgMzAuNDQ1LTY4IDY4LTY4czY4IDMwLjQ0NSA2OCA2OHYyNzJjMCAzNy41NTUtMzAuNDQ1IDY4LTY4IDY4ek01NDQgODUwYy0zNy41NTUgMC02OC0zMC40NDUtNjgtNjhzMzAuNDQ1LTY4IDY4LTY4YzM3LjU1NSAwIDY4IDMwLjQ0NSA2OCA2OHMtMzAuNDQ1IDY4LTY4IDY4eiI+DQogICAgICAgICAgICAgICAgICAgIDwvcGF0aD4NCiAgICAgICAgICAgICAgICA8L3N2Zz4=");
        &.fallback {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMjQiIGhlaWdodD0iMTAyNCIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCI+PHRpdGxlPjwvdGl0bGU+PGcgaWQ9Imljb21vb24taWdub3JlIj48L2c+PHBhdGggZmlsbD0iIzAwMCIgZD0iTTUxMiA5NzIuOGMtMjU0LjQ5MyAwLTQ2MC44LTIwNi4zMDktNDYwLjgtNDYwLjggMC0yNTQuNDkzIDIwNi4zMDctNDYwLjggNDYwLjgtNDYwLjggMjU0LjQ5MSAwIDQ2MC44IDIwNi4zMDcgNDYwLjggNDYwLjggMCAyNTQuNDkxLTIwNi4zMDkgNDYwLjgtNDYwLjggNDYwLjh6TTUxMiAxMDI0YzI4Mi43NzEgMCA1MTItMjI5LjIyOSA1MTItNTEycy0yMjkuMjI5LTUxMi01MTItNTEyYy0yODIuNzcgMC01MTIgMjI5LjIzLTUxMiA1MTJzMjI5LjIzIDUxMiA1MTIgNTEyek00MDkuNiAyMDQuOGMtMzcuNzA0IDAtNjguMjY3IDMwLjU2NC02OC4yNjcgNjguMjY3djI3My4wNjdjMCAzNy43MDQgMzAuNTYzIDY4LjI2NyA2OC4yNjcgNjguMjY3czY4LjI2Ny0zMC41NjMgNjguMjY3LTY4LjI2N3YtMjczLjA2N2MwLTM3LjcwMy0zMC41NjMtNjguMjY3LTY4LjI2Ny02OC4yNjd6TTQwOS42IDgxOS4yYzM3LjcwNCAwIDY4LjI2Ny0zMC41NjMgNjguMjY3LTY4LjI2N3MtMzAuNTYzLTY4LjI2Ny02OC4yNjctNjguMjY3Yy0zNy43MDQgMC02OC4yNjcgMzAuNTYzLTY4LjI2NyA2OC4yNjdzMzAuNTYzIDY4LjI2NyA2OC4yNjcgNjguMjY3ek03NzMuNjkgNDU1LjExbC0wLjMxNC02LjU1Yy0zLjA5OS0zMi40NTQtMjguOTI1LTU4LjI3OS02MS4zNzktNjEuMzc5bC02LjU3NC0wLjMxNC0xNTkuMjktMC4wMjR2LTQ1LjUxbDE1OS4yOSAwLjAyNCA3LjQ3OSAwLjI0MmM1Ni44OCAzLjY5IDEwMi4zNjYgNDkuMTc2IDEwNi4wNTYgMTA2LjA1NmwwLjI0MiA3LjQ3OXY0NS40NjZjMCA2MC4zMjQtNDYuOTQ3IDEwOS42ODQtMTA2LjI5OCAxMTMuNTM0bC03LjQ3OSAwLjI0Mi0yMi43NTcgMC4wMDN2NjguMjg3bC0xMzYuNTMzLTkxLjAyMyAxMzYuNTMzLTkxLjAyMHY2OC4yNDZsMjIuNzU3LTAuMDAzYzM3LjcgMCA2OC4yNjctMzAuNTY2IDY4LjI2Ny02OC4yNjd2LTQ1LjQ4OXoiPjwvcGF0aD48L3N2Zz4=");
        }
    }
    .warning + .content {
        height: 4rem;
        margin-top: 2rem;
    }
    .content {
        height: 8rem;
        align-items: center;
        justify-content: center;
        display: flex;
    }
}
