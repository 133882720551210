.active-button {
    cursor: pointer;
}
.notification-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
    max-width: 15rem;
    display: inline-block;
}
